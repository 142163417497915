import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #2c3e50;
  color: #bdc3c7;
  padding: 20px;
  text-align: center;
  font-size: 0.9rem;
  margin: 0;  /* Ensure no extra margin above */
`;

function Footer() {
  return (
    <FooterContainer>
      <p>© 2024 EchoBach, Inc. All rights reserved.</p>
    </FooterContainer>
  );
}

export default Footer;
