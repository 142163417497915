import React, { useEffect } from 'react';
import Header from './components/Header';
import Overview from './components/Overview';
import BenefitsSection from './components/BenefitsSection';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #1a1a1a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;  /* Ensure no margin around the container */
  padding: 0;
`;

function App() {
  useEffect(() => {
    if (window.location.protocol !== 'https:' && window.location.hostname !== 'localhost') {
      window.location.href = `https://${window.location.hostname}${window.location.pathname}${window.location.search}`;
    }
  }, []);

  return (
    <Container>
      <Header />
      <Overview />
      <BenefitsSection />
      <ContactForm />
      <Footer />
    </Container>
  );
}

export default App;
